// Global Variables
const storyTitleTop = document.querySelector('.story-info__title')

// 

function prevDef(e) {
    e.preventDefault();
}

// Number Separate
function numberSeparate(number) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}
const ns = document.querySelectorAll('.ns')
ns.forEach(function(e, i) {
    ns[i].innerText = numberSeparate(ns[i].innerText);
});

// Get Offset Element
function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

// Dropdown Menu
$('.dropdown-menu-trigger').click(function() {
    const thisDMWrapper = $(this).parents('.dropdown-menu-wrapper')
    const dMWrapper = $('.dropdown-menu-wrapper').not(thisDMWrapper)

    dMWrapper.find('.dropdown-menu').removeClass('_show')
    dMWrapper.find('.dropdown-menu-trigger').removeClass('_active')

    thisDMWrapper.find('.dropdown-menu').toggleClass('_show')
    thisDMWrapper.find('.dropdown-menu-trigger').toggleClass('_active')
})

$(document).click(function() {
    const dMWrapper = $('.dropdown-menu-wrapper')

    dMWrapper.find('.dropdown-menu').removeClass('_show')
    dMWrapper.find('.dropdown-menu-trigger').removeClass('_active')
})

$('.dropdown-menu-trigger').click(function(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
})

$('.collapsiblock').click(function(){
    $('.collapsiblock').not(this).each(function(){
        $(this).slideUp();
    });
    $(this).slideDown();
})

// Popup
const getPopupBtn = function() {
    document.onclick = function(e) {
        const body = document.getElementsByTagName('BODY')[0];
        if (e.target.hasAttribute('popup-btn') == true) {
            const popupBtnAttr = e.target.getAttribute('popup-btn')
            
            const popup = document.querySelector(`[popup="${popupBtnAttr}"]`)
            popup.classList.add('popup--show')
            body.classList.add('overflow-hidden')
        } 
        
        if (e.target.hasAttribute('popup-close') == true) {
            const popupCloseAttr = e.target.getAttribute('popup-close')
            
            const popup = document.querySelector(`[popup="${popupCloseAttr}"]`)
            popup.classList.remove('popup--show')
            body.classList.remove('overflow-hidden')
        }
    }
}
getPopupBtn()

// Collapse
$('.collapse__head').click(function() {
    $(this).parent('.collapse').toggleClass('collapse--expand')
    $(this).next('.collapse__body').slideToggle()
})

// Remove card
$('.remove-card').click(function() {
    $(this).parent('.removable-card').fadeOut()
})

document.addEventListener('scroll', function(e) {
    // Global Variables For Scroll
    const doc = document.documentElement;
    const docScrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
    const docScrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)

    const navbarOpacity = (docScrollTop * 0.7) / 100

    // NAVBAR STORY INFO
    const navbar = document.querySelectorAll('.navbar')
    // const navbarStory = document.querySelector('.navbar-story')
    // const navbarContainer = navbarStory.querySelector('.container > div')
    // const navbarTitle = navbarStory.querySelector('.navbar__title')

    navbar.forEach(function(e, i) {
        if (docScrollTop > 1) {
            navbar[i].style.boxShadow = '0px 12px 16px -8px rgba(36, 38, 40, 0.1)'
        } else {
            navbar[i].style.boxShadow = '0px 12px 16px -8px rgba(36, 38, 40, 0)'
        }
    });

    

    // Background & Box Shadow Navbar
    // if (docScrollTop < 1) {
    //     navbarContainer.style.backgroundColor = 'rgba(255, 255, 255, 0)'
    //     navbarContainer.style.boxShadow = '0px 12px 16px -8px rgba(36, 38, 40, 0)'
    // } else if (docScrollTop >= 1 && docScrollTop <= 150) {
    //     navbarContainer.style.backgroundColor = 'rgba(255, 255, 255, '+navbarOpacity+')'
    //     navbarContainer.style.boxShadow = '0px 12px 16px -8px rgba(36, 38, 40, '+ navbarOpacity / 10 +')'
    // } else if (docScrollTop > 150) {
    //     navbarContainer.style.backgroundColor = 'rgba(255, 255, 255, 1)'
    //     navbarContainer.style.boxShadow = '0px 12px 16px -8px rgba(36, 38, 40, 0.1)'
    // }

    // Color Text Navbar
    // if (docScrollTop >= 50) {
    //     navbar.classList.replace('text-white', 'text-current-light')
    // } else {
    //     navbar.classList.replace('text-current-light', 'text-white')
    // }

    // Show/hide Title Navbar
    // if (docScrollTop >= getOffset(storyTitleTop).top) {
    //     navbarTitle.classList.replace('text-transparent', 'text-current-light')
    // } else {
    //     navbarTitle.classList.replace('text-current-light', 'text-transparent')
    // }
});

